import { sha256 } from 'crypto-hash'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import ArticleNeighborhood from './ArticleNeighborhood'
import Callback from './Callback'
import Faq from './Faq'
import Home from './Home'
import Login from './Login'
import Integrations from './Integrations'
import PreviouslyViewed from './PreviouslyViewed'
import Tree from './Tree'
import { ViewerProvider } from './../hooks/useViewer'
import ProtectedRoute from './../routes/ProtectedRoute'

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/graphql' : 'https://api.gurney.science/graphql'
const loginEndpointUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/login' : 'https://api.gurney.science/api/login'

const persistedQueryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true
})
const httpLink = createHttpLink({ uri: apiUrl, credentials: 'include' })
const link = persistedQueryLink.concat(httpLink)
const client = new ApolloClient({ link, cache: new InMemoryCache() })

function App () {
  return(
    <>
      <HelmetProvider>
        <Router>
          <ApolloProvider client={client}>
            <ViewerProvider>
              <Routes>
                <Route
                  path='/'
                  exact
                  element={<Home />}
                />
                <Route
                  path='/faq'
                  exact
                  element={<Faq />}
                />
                <Route
                  path='/journal-tree'
                  exact
                  element={<Tree />}
                />
                <Route
                  path='/previously-viewed'
                  exact
                  element={
                    <ProtectedRoute>
                      <PreviouslyViewed />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/integrations'
                  exact
                  element={<Integrations />}
                />
                <Route
                  path='/pmid/:pmid'
                  element={<ArticleNeighborhood />}
                />
                <Route path='/login' element={<Login />} />
                <Route path='/callback' element={<Callback loginEndpointUrl={loginEndpointUrl} />} />
              </Routes>
            </ViewerProvider>
          </ApolloProvider>
        </Router>

        <div className='App'>
          <Helmet>
            <title>Gurney - Know your field</title>
          </Helmet>
        </div>
     </HelmetProvider>
    </>
  )
}

export default App
