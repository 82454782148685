import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineSearch } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'
import useViewer from '../hooks/useViewer'
import demoImage from '../assets/demo.gif'

const DOI_REGEXP = new RegExp(/^10.\d{4,9}/)

const ARTICLE_BY_DOI = gql`
  query ArticleByDoi($doi: String!) {
    article(doi: $doi) {
      pmid
    }
  }
`

const Home = () => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchQueryDoi, setSearchQueryDoi] = useState(null)
  const [searchErrorVisibility, setSearchErrorVisibility] = useState('invisible')
  const [neighborhoodArticlePmid, setNeighborhoodArticlePmid] = useState(null)
  const { called, loading: articleLoading, error, data } = useQuery(
    ARTICLE_BY_DOI,
    { variables: { doi: searchQueryDoi }, skip: !searchQueryDoi }
  )

  const navigateToArticleNeighborhood = () => {
    const pmidPath = `/pmid/${neighborhoodArticlePmid}`
    navigate(pmidPath)
  }

  const updateSearchQuery = (e) => {
    return setSearchQuery(e.target.value.trim())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSearchErrorVisibility('invisible')

    if (DOI_REGEXP.test(searchQuery)) {
      setSearchQueryDoi(searchQuery)
    } else {
      const pmid = parseInt(searchQuery)

      if (pmid === 0) {
        setSearchErrorVisibility('visible')
      } else {
        setNeighborhoodArticlePmid(parseInt(searchQuery))
      }
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') { handleSubmit(e) }
  }
  const { viewer: user } = useViewer()

  React.useEffect(() => {
    setSearchErrorVisibility('invisible')

    if (neighborhoodArticlePmid) {
      setSearchQuery(neighborhoodArticlePmid)
      navigateToArticleNeighborhood()
    }
  }, [neighborhoodArticlePmid])

  React.useEffect(() => {
    if (called && articleLoading) { return }

    if (error || (searchQueryDoi && !data?.article)) {
      setSearchErrorVisibility('visible')
      return
    }

    if (data && data.article.pmid) {
      setNeighborhoodArticlePmid(data.article.pmid)
    }
  }, [searchQueryDoi, data, error, articleLoading])

  const accountActionLink = user ?
    (<a href='/previously-viewed' className={'hidden sm:flex hover:underline text-base font-sans text-stone-700'}>Dashboard</a>) :
      (<a href='/login' className={'hidden sm:flex hover:underline text-base font-sans text-stone-700'}>Log in</a>)

  return(
    <div className={'overflow-auto'} style={{height: window.innerHeight}}>
      <div className={'h-full w-full'}>
        <div className={'bg-stone-50'}>
          <div className={'flex flex-row w-full items-center'}>
            <div className={'flex-1 px-8 py-4'}>
              <Logo />
            </div>
            <div className={'text-right pr-8'}>{accountActionLink}</div>
          </div>
          <div className={'flex flex-row px-8 py-12'}>
            <div className={'flex flex-col w-full items-center text-center'}>
              <div className={'flex flex-col font-serif'}>
                <span className={'text-stone-700 text-4xl sm:text-6xl'}>
                  Know your field.
                </span>
                <span className={'pt-8 text-stone-700 text-xl 2xl:text-2xl'}>
                  Contextualize and explore PubMed papers, visually.
                </span>
              </div>
              <div className={'hidden sm:flex flex-col'}>
                <div className={'flex flex-row font-sans text-xl pt-12'}>
                  <IconContext.Provider value={{ style: { padding: '8px', borderLeft: '2px solid #e7e5e4', borderTop: '2px solid #e7e5e4', borderBottom: '2px solid #e7e5e4', backgroundColor: 'white',  display: 'inline-flex', color: '#44403c' } }}>
                    <AiOutlineSearch
                      size={52}
                      title={'Search by DOI or PMID'}
                    />
                  </IconContext.Provider>

                  <input
                    className={'outline-none [webkit-appearance:none] w-60 h-[52px] sm:w-72 p-2 border-r-2 border-y-2 border-solid border-stone-200'}
                    onKeyPress={onKeyPress}
                    onChange={updateSearchQuery}
                    placeholder={'Search by DOI or PMID'}
                    value={searchQuery}
                  />
                </div>
                <div className={'flex flex-row pt-4'}>
                  <span className={`${searchErrorVisibility} font-sans text-sm text-red-700`}>Article not found</span>
                </div>
              </div>
            </div>
          </div>
          <div className={'flex flex-row pb-12'}>
            <div className={'flex flex-col w-1/6'}></div>
            <div className={'flex flex-col w-2/3'}>
              <figure>
                <img className={'shadow-lg shadow-stone-700'} height={2192} width={3584} src={demoImage} alt={'Gurney demo'} />
              </figure>
            </div>
            <div className={'flex flex-col w-1/6'}></div>
          </div>
        </div>
        <div className={'flex flex-row w-full bg-stone-50 place-content-center py-4'}>
          <span className={'font-sans text-sm text-stone-700 mr-4'}>© Gurney 2023</span>
          <a href='https://gurney.canny.io/' className={'font-sans text-sm text-stone-700 mr-4 underline'}>Roadmap</a>
          <a href='/faq' className={'font-sans text-sm text-stone-700 underline'}>FAQ</a>
        </div>
      </div>
    </div>
  )
}

export default Home
