import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import numeral from 'numeral'
import React, { useState } from 'react'
import { IconContext } from 'react-icons'
import { MdPeople } from 'react-icons/md'
import ArticleActions from './ArticleActions'

const ARTICLE_ABSTRACT = gql`
  query ArticleAbstract($pmid: Int!) {
    articleAbstract(pmid: $pmid)
  }
`

const ArticleSummary = ({
  selectedArticle,
  selectedArticleAuthors,
  selectedArticlePmid,
  neighborhoodArticlePmid,
  setNeighborhoodArticlePmid,
  scatterplotVisibility,
  authorListVisibility,
  setScatterplotVisibility,
  setAuthorListVisibility,
  setNeighborhoodLoaded,
}) => {
  const [abstractText, setAbstractText] = useState(null)
  const variables =  { pmid: selectedArticlePmid }
  const skipArticleAbstractQuery = !selectedArticlePmid
  const { loading, error, data } = useQuery(
    ARTICLE_ABSTRACT,
    { variables, skip: skipArticleAbstractQuery },
  )

  React.useEffect(() => {
    if (loading || error) { return }
    setAbstractText(data?.articleAbstract)
  }, [selectedArticlePmid, data, error, loading])

  if (selectedArticle && selectedArticleAuthors) {
    const { citationsCount: totalCitationsCount } = selectedArticle
    const formattedCitationsCount = numeral(totalCitationsCount).format('0a')
    const firstAuthor = selectedArticleAuthors?.find(({ authorOrder }) => authorOrder === 1)
    const firstAuthorFullName = firstAuthor && firstAuthor.lastName.length > 0 && firstAuthor.foreName.length > 0 ?
      [firstAuthor.lastName, firstAuthor.foreName].join(', ') :
      undefined

    const neighborhoodCitations = selectedArticle.citedByArticlePmids
    let citationsCountText = ''

    citationsCountText += `${formattedCitationsCount.toLocaleString('en-us')}`

    if (neighborhoodCitations) {
      const formattedNeighborhoodCitationsCount = numeral(neighborhoodCitations.length).format('0a')
      citationsCountText += ` known | ${formattedNeighborhoodCitationsCount} neighborhood `
    }

    citationsCountText += ' citations'

    let formattedAbstractText = abstractText

    if (!loading && !abstractText) { formattedAbstractText = 'Not available' }
    const formattedFirstAuthorFullName = firstAuthorFullName ? firstAuthorFullName : 'Author not available. '
    const toggleAuthorListVisibility = () => {
      const newAuthorListVisibility = authorListVisibility === 'hidden' ? 'visible' : 'hidden'
      const newScatterplotVisibility = scatterplotVisibility === 'hidden' ? 'visible' : 'hidden'
      setScatterplotVisibility(newScatterplotVisibility)
      return setAuthorListVisibility(newAuthorListVisibility)
    }
    const stone700HexCode = '#44403c'

    const icon = (<IconContext.Provider value={{ style: { color: stone700HexCode } }}>
        <MdPeople size={25} />
      </IconContext.Provider>)

    const firstAuthorText = selectedArticleAuthors?.length > 1 ?
      `${formattedFirstAuthorFullName}, et. al.` :
      formattedFirstAuthorFullName

    const showAuthorsButton = (
      <button
        className={'text-base text-left px-4 hover:bg-stone-200 w-fit'}
        onClick={toggleAuthorListVisibility}
        onMouseOver={({ target }) => target.style.cursor = 'pointer'}
      >
        <span className={'flex flex-row space-x-2 py-2'}>
          <span>{firstAuthorText}</span>
          <span>{icon}</span>
        </span>
      </button>
    )

    const hideAuthorsButton = (
      <button className={'text-base text-left px-4 bg-stone-200 w-fit'} onClick={toggleAuthorListVisibility}>
        <span className={'flex flex-row space-x-2 py-2 mx-0'}>
          <span>{firstAuthorText}</span>
          <span>{icon}</span>
        </span>
      </button>
    )
    const firstAuthorFragment = (
      <>
        {authorListVisibility === 'hidden' ? showAuthorsButton : hideAuthorsButton}
        <p className={'pl-4'}>{firstAuthor?.affiliation}</p>
      </>
    )

    return(
      <>
        <Helmet>
          <meta name={'citation_abstract'} content={abstractText} />
        </Helmet>
        <div className={'h-full flex flex-row'}>
          <div className={'full flex flex-col font-sans pt-4 bg-stone-50 border-b border-l border-solid border-stone-200'}>
            <div className={'flex pr-4 overflow-x-hidden overflow-y-auto'} style={{flex: 9}}>
              <div className={'space-y-4'}>
                <div className={'flex flex-col space-y-2 pb-2'}>
                  <span className={'pl-4 text-base'}><span className={'italic'}>{selectedArticle.journalTitle}</span>, {selectedArticle.formattedPublicationDate}.</span>
                  <span className={'pl-4 text-2xl font-serif'}>{selectedArticle.title}</span>
                  {firstAuthorFullName ? firstAuthorFragment : (<span className={'pl-4'}>{formattedFirstAuthorFullName}</span>)}
                  <span className={'pl-4 text-base'}>{citationsCountText}</span>
                </div>
                <div className={'flex flex-col pl-4 space-y-2'}>
                  <span className={'text-xl font-bold font-serif'}>Abstract</span>
                  <span className={'text-lg'}>{formattedAbstractText}</span>
                </div>
              </div>
            </div>

            <ArticleActions
              selectedArticlePmid={selectedArticle.pmid}
              setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
              setNeighborhoodLoaded={setNeighborhoodLoaded}
            />
          </div>
        </div>
      </>
    )
  } else {
    return(
      <>
        <div className={'h-full flex flex-col font-serif'}>
          <div className={'flex flex-5 px-4 mt-4 overflow-x-hidden overflow-y-auto'} style={{flex: 5}}>
          </div>

          <div className={'flex flex-1 flex-row content-evenly py-2 border-t-0 border-solid border-stone-200'}>
          </div>
        </div>
      </>
    )
  }
}

export default ArticleSummary
