import { orderBy } from 'lodash'
import numeral from 'numeral'
import neighborTypeColors from './../lib/neighborTypeColors'

const neighborTypeLabelText = {
  'ANCESTOR': 'ANCESTOR',
  'SIBLING': 'CO-CITATION',
  'DESCENDANT': 'DESCENDANT',
  'AUTHORED_BY_TOP_NEIGHBORHOOD_AUTHOR': 'AUTHORED BY TOP NEIGHBORHOOD AUTHOR'
}

const SelectedArticlesList = ({
  articles,
  selectedArticlePmid,
  setSelectedArticle,
}) => {
  const orderedArticles = orderBy(articles, ['citedByArticlePmids.length', 'citationsCount'], ['desc', 'desc'])
  const items = orderedArticles.map((article) => {
    const {
      citationsCount,
      citedByArticlePmids,
      journalTitle,
      neighborType,
      pmid,
      title,
      formattedPublicationDate,
    } = article
    const formattedCitationsCount = numeral(citationsCount).format('0a')
    const formattedNeighborhoodCitationsCount = numeral(citedByArticlePmids.length).format('0a')
    let neighborTypeLabel = ''

    if (neighborType) {
      const neighborTypeBgClass = neighborTypeColors[neighborType]?.bgClass
      const textColor = neighborTypeColors[neighborType]?.textColor
      neighborTypeLabel = (
        <span className={`${neighborTypeBgClass} font-sans text-sm ${textColor} uppercase px-1 mr-1`} data-pmid={pmid}>{neighborTypeLabelText[neighborType]}</span>
      )
    }
    const similarlyTitled = article.titleSimilarity >= 0.4
    const similarlyTitledLabel = !similarlyTitled ? '' : (
      <span className={'bg-green-400 font-sans text-sm text-black uppercase px-1 mr-1'} data-pmid={pmid}>similarly titled</span>
    )
    const buttonBackground = pmid === selectedArticlePmid ? 'bg-stone-50' : 'bg-white'

    return (
      <div className={`tooltip-row flex flex-column border-b border-solid border-stone-200`}>
        <div className={'w-full border-none flex-row bg-white'}>
          <button
            data-pmid={pmid}
            className={`w-full ${buttonBackground} hover:bg-stone-50 space-y-2 p-4 text-left`}
            onClick={() => {
              const selectedPmid = pmid
              const newSelectedArticle = articles.find(({ pmid }) => pmid === selectedPmid)
              return setSelectedArticle(newSelectedArticle)
            }}
          >
            {neighborTypeLabel}
            {similarlyTitledLabel}
            <span className={'font-sans text-base my-1'} data-pmid={pmid}>
              <span className={'italic'}>{journalTitle}</span>
              <span>, {formattedPublicationDate}.</span>
            </span>
            <p className={'font-serif text-lg my-1'} data-pmid={pmid}>{title}</p>
            <p className={'font-sans text-base my-1'}>{formattedCitationsCount} known | {formattedNeighborhoodCitationsCount} neighborhood citations</p>
          </button>
        </div>
      </div>
    )
  })
  const maxWidth = window.innerWidth * 0.75
  const maxHeight = window.innerHeight * 0.66

  if (articles.length === 0) { return (<></>) }

  return (
    <div className={`tooltip absolute top-48 left-0 bg-white mt-2 shadow-lg shadow-stone-700/75`}>
      <div className={'tooltip-multiselect max-h-48 overflow-y-auto overflow-x-hidden'} style={{maxWidth, maxHeight}}>
        {items}
      </div>
    </div>
  )
}

export default SelectedArticlesList
