import { IconContext } from 'react-icons'
import { AiOutlineExpandAlt } from 'react-icons/ai'
import { MdAdsClick, MdKeyboardAlt, MdOutlineMouse } from 'react-icons/md'

const ScatterplotControls = ({ isExpanded, setIsExpanded }) => {
  const onMouseOver = ({ target }) => target.style.cursor = 'pointer'
  const controlsDescription = (<div className={'flex flex-col py-4 space-y-2 font-sans text-stone-700 text-lg'}>
      <div className={'flex flex-row'}>
        <div className={'flex flex-col w-28 pr-2 items-start'}><MdOutlineMouse size={25} /></div>
        <div className={'flex flex-col'}>
          <span>Scroll to zoom by date</span>
        </div>
      </div>
      <div className={'flex flex-row'}>
        <div className={'flex flex-col w-28 items-start'}>
          <div className={'flex flex-row'}>
            <span className={'pr-2'}><MdKeyboardAlt size={25} /></span>
            <span className={'pr-2'}><MdOutlineMouse size={25} /></span>
          </div>
        </div>
        <div className={'flex flex-col'}>
          <span>Ctrl + Scroll to zoom by field/journal</span>
        </div>
      </div>
      <div className={'flex flex-row'}>
        <div className={'flex flex-col w-28 items-start'}>
          <div className={'flex flex-row'}>
            <span className={'pr-2'}><MdKeyboardAlt size={25} /></span>
            <span className={'pr-2'}><MdAdsClick size={25} /></span>
            <span className={'pr-2'}><MdOutlineMouse size={25} /></span>
          </div>
        </div>
        <div className={'flex flex-col'}>
          <span>Shift + Click + Drag to draw a rectangle and select multiple articles</span>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className={'absolute bottom-0 left-0 p-4 font-sans text-align-left bg-stone-200 font-sans'}>
        <IconContext.Provider value={{ style: { color: '#44403c' } }}>
          <div className={'flex flex-row space-x-2 items-center justify-items-end'}>
            <button className={'w-full text-xl text-left bg-stone-200 text-stone-700'} onClick={() => setIsExpanded(!isExpanded)}>Controls</button>
            <AiOutlineExpandAlt
              size={25}
              onClick={() => setIsExpanded(!isExpanded)}
              onMouseOver={onMouseOver}
            />
          </div>
        </IconContext.Provider>
          {isExpanded && controlsDescription}
      </div>
    </div>
  )
}

export default ScatterplotControls
