import * as d3 from 'd3'
import React from 'react'
import treeData from './../lib/tree-edges-20230707.json'

const Tree = () => {
  const svgRef = React.useRef(null)

  React.useEffect(() => {
    const width = window.innerWidth
    const dx = 70
    const dy = 35
    const margin = ({ top: 10, right: 120, bottom: 10, left: 40 })
    const svgEl = d3.select(svgRef.current)

    const tree = d3.tree().nodeSize([dx, dy]).size([1000,1000])
    const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x)
    const hierarchicalTreeData = d3.stratify()
      .id((e) => e.name)
      .parentId((e) => e.parent)(treeData)
    const root = d3.hierarchy(hierarchicalTreeData)

    root.x0 = dy / 2
    root.y0 = 0
    root.descendants().forEach((d, i) => {
      d.id = i
      d._children = d.children
      if (d.depth && d.data.name?.length !== 7) { d.children = null }
    })

    const svg = svgEl
        .style("font", "12px sans-serif")
        .style("user-select", "none")

    const gLink = svg.append("g")
        .attr("fill", "none")
        .attr("stroke", "#555")
        .attr("stroke-opacity", 0.4)
        .attr("stroke-width", 1.5)

    const gNode = svg.append("g")
        .attr("cursor", "pointer")
        .attr("pointer-events", "all")

    function update(event, source) {
      const duration = event?.altKey ? 2500 : 250
      const nodes = root.descendants().reverse()
      const links = root.links()

      // Compute the new tree layout.
      tree(root)

      let left = root
      let right = root
      root.eachBefore(node => {
        if (node.x < left.x) left = node
        if (node.x > right.x) right = node
      })

      const height = right.x - left.x + margin.top + margin.bottom

      const transition = svg.transition()
          .duration(duration)
          .attr("viewBox", [-margin.left, left.x - margin.top, width, height])
          .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"))

      // Update the nodes…
      const node = gNode.selectAll("g")
        .data(nodes, d => d.id)

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node.enter().append("g")
          .attr("transform", d => `translate(${source.y0},${source.x0})`)
          .attr("fill-opacity", 0)
          .attr("stroke-opacity", 0)
          .on("click", (event, d) => {
            d.children = d.children ? null : d._children
            update(event, d)
          })

      nodeEnter.append("circle")
          .attr("r", 2.5)
          .attr("fill", d => d._children ? "#555" : "#999")
          .attr("stroke-width", 10)

      nodeEnter.append("text")
          .attr("dy", "0.31em")
          .attr("x", d => d._children ? -6 : 6)
          .attr("text-anchor", d => d._children ? "end" : "start")
          .text((d) => {
            let label = d.data.id.slice(0, 50)
            if (d.data.id.length > 50) { label += ' ...' }
            return label
          })
        .clone(true).lower()
          .attr("stroke-linejoin", "round")
          .attr("stroke-width", 3)
          .attr("stroke", "white")

      // Transition nodes to their new position.
      const nodeUpdate = node.merge(nodeEnter).transition(transition)
          .attr("transform", d => `translate(${d.y},${d.x})`)
          .attr("fill-opacity", 1)
          .attr("stroke-opacity", 1)

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node.exit().transition(transition).remove()
          .attr("transform", d => `translate(${source.y},${source.x})`)
          .attr("fill-opacity", 0)
          .attr("stroke-opacity", 0)

      // Update the links…
      const link = gLink.selectAll("path")
        .data(links, d => d.target.id)

      // Enter any new links at the parent's previous position.
      const linkEnter = link.enter().append("path")
          .attr("d", d => {
            const o = {x: source.x0, y: source.y0}
            return diagonal({source: o, target: o})
          })

      // Transition links to their new position.
      link.merge(linkEnter).transition(transition)
          .attr("d", diagonal)

      // Transition exiting nodes to the parent's new position.
      link.exit().transition(transition).remove()
          .attr("d", d => {
            const o = {x: source.x, y: source.y}
            return diagonal({source: o, target: o})
          })

      // Stash the old positions for transition.
      root.eachBefore(d => {
        d.x0 = d.x
        d.y0 = d.y
      })
    }

    update(null, root)
  }, [treeData])

  return(
    <div className={'m-8'}>
      <svg ref={svgRef}></svg>
    </div>
  )
}

export default Tree
