import { gql, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { Helmet } from 'react-helmet-async'
import React, { useState } from 'react'
import ContactSupport from './ContactSupport'
import Header from './Header'
import useViewer from '../hooks/useViewer'

const headerHeight = 80

const VIEWED_ARTICLES = gql`
  query ViewedArticles {
    viewedArticles(orderBy: { updatedAt: desc }) {
      pmid
      title
      updatedAt
    }
  }
`
const PreviouslyViewed = () => {
  const { viewer: user } = useViewer()
  const [viewedArticles, setViewedArticles] = useState([])
  const [neighborhoodArticlePmid, setNeighborhoodArticlePmid] = useState('')
  const height = window.innerHeight - headerHeight
  const { loading, error, data } = useQuery(VIEWED_ARTICLES)

  React.useEffect(() => {
    if (loading || error) { return }
    const { viewedArticles: viewedArticlesData } = data
    setViewedArticles([...viewedArticlesData])
  }, [data, loading, error])

  const loadingUserHtml = (
    <>
      <Helmet><title>Gurney</title></Helmet>
      <div className={`max-height-[${headerHeight}]`}>
        <Header
          neighborhoodArticlePmid={neighborhoodArticlePmid}
          setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
        />
      </div>
      <div className={'overflow-auto'} style={{height}}>
        <div className={'flex flex-row sm:px-44 px-8 pt-8'}><span className={'font-sans text-3xl text-stone-900'}>Previously viewed</span></div>
      </div>
    </>
  )

  if (!user) {
    return loadingUserHtml
  }

  const localeStringOptions = { weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  const previouslyViewedCountText = viewedArticles.length > 0 ? `(${viewedArticles.length})` : ''

  return(
    <>
      <Helmet><title>Gurney - Previously viewed</title></Helmet>
      <Header
        neighborhoodArticlePmid={neighborhoodArticlePmid}
        setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
      />
      <div className={'overflow-auto pb-8'} style={{height}}>
        <div className={'flex flex-row sm:px-44 p-8'}><span className={'font-sans text-2xl sm:text-3xl text-stone-900'}>Previously viewed {previouslyViewedCountText}</span></div>
        {viewedArticles.map((article) => {
           const largeScreenViewedAt = DateTime.fromISO(article.updatedAt)
             .toLocaleString(localeStringOptions)
           const smallScreenViewedAt = DateTime.fromISO(article.updatedAt)
             .toLocaleString({ weekday: 'short', month: 'short', day: '2-digit' })

           return(
             <div className={'flex flex-row sm:px-44 px-8 py-2 space-x-4 font-sans text-stone-600'}>
               <div className={'flex flex-col sm:w-48 w-1/3 text-base'}>
                 <span className={'sm:flex hidden'}>{largeScreenViewedAt}</span>
                 <span className={'sm:hidden'}>{smallScreenViewedAt}</span>
               </div>
               <div className={'flex flex-col sm:w-2/3 w-2/3 text-xl text-stone-900'}>
                 <a className={'hover:underline'} href={`/pmid/${article.pmid}`}>{article.title}</a>
               </div>
             </div>
           )
        })}
        <ContactSupport />
      </div>
    </>
  )
}

export default PreviouslyViewed
