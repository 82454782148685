const Logo = () => {
  const emerald600HexCode = '#059669'
  return(
    <svg className={'w-[86px] h-[36px]'}>
      <a href='/'>
        <text fill={emerald600HexCode} x={0} y={30} className={'text-4xl font-logo'}>Gurney</text>
      </a>
    </svg>
  )
}

export default Logo
