import { gql, useMutation, useQuery } from '@apollo/client'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import ArticleSummary from './ArticleSummary'
import ContactSupport from './ContactSupport'
import Header from './Header'
import ArticleNeighborhoodFilters from './ArticleNeighborhoodFilters'
import ArticleAuthors from './ArticleAuthors'
import ScatterplotContainer from './ScatterplotContainer'
import useViewer from '../hooks/useViewer'

const headerHeight = 160

const scatterplotDimensions = {
  width: window.innerWidth * 0.77,
  height: window.innerHeight - headerHeight,
  margin: { top: 0, right: 20, bottom: 60, left: 300 }
}

const articleSummaryHeight = scatterplotDimensions.height + (headerHeight/2)

const sidebarWidth = window.innerWidth * 0.23

const CREATE_VIEWED_ARTICLE = gql`
  mutation CreateViewedArticle($input: ViewedArticleInput!) {
    createViewedArticle(input: $input)
  }
`

const ARTICLES = gql`
  query Articles($pmids: [Int!]!) {
    articles(pmids: $pmids) {
      pmid
      abstractText
      doi
      issue
      volume
      language
      x
      y
      title
      journalTitle
      citationsCount
      publishedOn
    }
  }
`

const AUTHORS = gql`
  query Authors($articlePmid: Int!) {
    authors(articlePmid: $articlePmid) {
      affiliation
      authorId
      authorOrder
      foreName
      lastName
    }
  }
`

const ArticleNeighborhood = () => {
  const location = useLocation()
  const pathnameParts = location.pathname.split('/')
  const defaultSearchQueryPmid = parseInt(pathnameParts[2])
  const { viewer: user } = useViewer()
  const [neighborhoodArticlePmid, setNeighborhoodArticlePmid] = useState(defaultSearchQueryPmid)
  const [keyword, setKeyword] = useState('')
  const [maxKnownCitationsCount, setMaxKnownCitationsCount] = useState(0)
  const [minKnownCitationsCount, setMinKnownCitationsCount] = useState(0)
  const [maxNeighborhoodCitationsCount, setMaxNeighborhoodCitationsCount] = useState(0)
  const [minNeighborhoodCitationsCount, setMinNeighborhoodCitationsCount] = useState(0)
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [selectedArticleAuthors, setSelectedArticleAuthors] = useState(null)
  const [neighborhoodArticleTitle, setNeighborhoodArticleTitle] = useState('')
  const [scatterplotVisibility, setScatterplotVisibility] = useState('visible')
  const [authorListVisibility, setAuthorListVisibility] = useState('hidden')
  const [neighborhoodLoaded, setNeighborhoodLoaded] = useState(false)
  const variables = { pmids: [neighborhoodArticlePmid] }
  const [createViewedArticleFunction] = useMutation(
    CREATE_VIEWED_ARTICLE,
    { variables: { input: { pmid: neighborhoodArticlePmid } } },
  )

  const {
    called: articleQueryCalled,
    loading: articleLoading,
    error: articleQueryError,
    data: articleData,
  } = useQuery(ARTICLES, { variables })

  const {
    data: authorsData,
    loading: authorsLoading,
    error: authorsError,
    refetch: refetchAuthors,
  } = useQuery(
    AUTHORS,
    { variables: { articlePmid: neighborhoodArticlePmid } },
  )

  const loadingUserHtml = (
    <>
      <Helmet><title>Gurney</title></Helmet>
      <div className={`max-height-[${headerHeight}]`}>
        <Header
          neighborhoodArticlePmid={neighborhoodArticlePmid}
          setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
          showLoadingIndicator={!neighborhoodLoaded}
        />
      </div>
    </>
  )

  const articleLoadingHtml = (
    <>
      <Helmet><title>Gurney</title></Helmet>
      <div className={`max-height-[${headerHeight}]`}>
        <Header
          neighborhoodArticlePmid={neighborhoodArticlePmid}
          setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
          showLoadingIndicator={!neighborhoodLoaded}
        />
        <div className={`font-sans overflow-hidden`}>
          <div className={'flex flex-row items-center'}>
            <div className={'flex flex-1 pl-8 py-4 border-b border-solid border-stone-200 bg-stone-50'}>
            </div>
          </div>
        </div>
      </div>

      <div className={'flex flex-grow-0 flex-shrink-0'}>
        <div className={'flex flex-1 text-center'}>
          <div></div>
          <div className={'flex-1'}>
            <p className={'m-12 font-serif text-4xl text-center'}>Finding article ...</p>
          </div>
        </div>
      </div>
    </>
  )

  const articleNotFoundHtml = (
    <>
      <Helmet><title>Gurney - Not found</title></Helmet>
      <div className={`max-height-[${headerHeight}]`}>
        <Header
          neighborhoodArticlePmid={neighborhoodArticlePmid}
          setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
        />
        <div className={`font-sans overflow-hidden`}>
          <div className={'flex flex-row items-center'}>
            <div className={'flex flex-1 pl-8 py-4 border-b border-solid border-stone-200 bg-stone-50'}>
            </div>
          </div>
        </div>
      </div>

      <div className={'flex flex-grow-0 flex-shrink-0'}>
        <div className={'flex flex-1 text-center'}>
          <div></div>
          <div className={'flex-1'}>
            <p className={'m-12 font-serif text-4xl text-center'}>Article for PMID {neighborhoodArticlePmid} not found. Was the article published after June 2023?</p>
          </div>
        </div>
      </div>
    </>
  )

  React.useEffect(() => {
    if (articleLoading) {
      if (neighborhoodLoaded === true) {
        setNeighborhoodLoaded(false)
      }
      return
    }

    if (articleQueryError) { return }

    const { articles } = articleData
    const article = articles[0]

    if (article) {
      const formattedPublicationDate = DateTime.fromISO(article.publishedOn).toFormat('yyyy LLL')
      const formattedTitle = article.title.length > 150 ? article.title.slice(0, 150) + ' ...' : article.title
      setNeighborhoodArticleTitle(formattedTitle)
      setSelectedArticle({ ...article, formattedPublicationDate })
    } else {
      setSelectedArticle(null)
    }
  }, [neighborhoodArticlePmid, articleData, articleQueryError, articleLoading])

  React.useEffect(() => {
    if (selectedArticle) {
      refetchAuthors({ articlePmid: selectedArticle.pmid })
      createViewedArticleFunction({
        variables: { input: { pmid: selectedArticle.pmid } },
      })
    }
  }, [selectedArticle, createViewedArticleFunction, refetchAuthors])

  React.useEffect(() => {
    if (authorsLoading || authorsError) { return }
    if (authorsData) {
      const orderedAuthors = orderBy(authorsData.authors, 'authorOrder')
      setSelectedArticleAuthors(orderedAuthors)
    }
  }, [authorsData, authorsError, authorsLoading])

  const articleNotFound = !selectedArticle && !articleLoading && articleQueryCalled

  if (articleNotFound) {
    return articleNotFoundHtml
  } else if (articleLoading) {
    return articleLoadingHtml
  } else if (articleQueryError) {
    return(
      <>
        <Helmet><title>Gurney - Error</title></Helmet>
        <h1>Error</h1>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Gurney - {selectedArticle.title}</title>
        <meta name={'citation_public_url'} content={`https://pubmed.ncbi.nlm.nih.gov/${selectedArticle.pmid}`} />
        <meta name={'citation_title'} content={selectedArticle.title} />
        <meta name={'citation_date'} content={selectedArticle.publishedOn.slice(0,7)} />
        <meta name={'citation_journal_title'} content={selectedArticle.journalTitle} />
        <meta name={'citation_issue'} content={selectedArticle.issue} />
        <meta name={'citation_volume'} content={selectedArticle.volume} />
        <meta name={'citation_pmid'} content={selectedArticle.pmid} />
        <meta name={'citation_doi'} content={selectedArticle.doi} />
        {selectedArticleAuthors?.map(({ foreName, lastName }) => {
          return(<meta name={'citation_author'} content={[lastName, foreName].join(', ')} />)
        })}
      </Helmet>
      <div className={`max-height-[${headerHeight}]`}>
        <Header
          neighborhoodArticlePmid={neighborhoodArticlePmid}
          setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
          showLoadingIndicator={!neighborhoodLoaded}
        />
      </div>

      <div className={'sm:hidden'}>
        <div className={'px-8 py-4 font-sans text-lg'}>Sorry, Gurney doesn't work on small screens yet!</div>
      </div>

      <div className={'hidden sm:flex'}>
        <div className={'flex flex-col flex-grow-0 flex-shrink-0'} style={{width: scatterplotDimensions.width}}>
          <div className={'flex flex-row items-center font-sans overflow-hidden'}>
            <div className={'flex flex-1 flex-col h-24 pl-8 py-4 space-y-2 border-b border-solid border-stone-200 bg-stone-50'}>
              <span className={'text-2xl font-serif'}>{neighborhoodArticleTitle}</span>
              <div className={'flex flex-row'}>
                <span className={'text-base w-fit'}>
                  Article neighborhood
                </span>
              </div>
            </div>
          </div>
          <ArticleNeighborhoodFilters
            keyword={keyword}
            maxKnownCitationsCount={maxKnownCitationsCount}
            minKnownCitationsCount={minKnownCitationsCount}
            setMinKnownCitationsCount={setMinKnownCitationsCount}
            maxNeighborhoodCitationsCount={maxNeighborhoodCitationsCount}
            minNeighborhoodCitationsCount={minNeighborhoodCitationsCount}
            setKeyword={setKeyword}
            setMinNeighborhoodCitationsCount={setMinNeighborhoodCitationsCount}
          />
          <div className={authorListVisibility}>
            <ArticleAuthors
              selectedArticleAuthors={selectedArticleAuthors || []}
              height={scatterplotDimensions.height * 0.9}
            />
          </div>
          <div className={scatterplotVisibility}>
            <ScatterplotContainer
              dimensions={scatterplotDimensions}
              keyword={keyword}
              minKnownCitationsCount={minKnownCitationsCount}
              minNeighborhoodCitationsCount={minNeighborhoodCitationsCount}
              neighborhoodArticlePmid={neighborhoodArticlePmid}
              neighborhoodLoaded={neighborhoodLoaded}
              selectedArticle={selectedArticle}
              setMaxKnownCitationsCount={setMaxKnownCitationsCount}
              setMaxNeighborhoodCitationsCount={setMaxNeighborhoodCitationsCount}
              setSelectedArticle={setSelectedArticle}
              setNeighborhoodLoaded={setNeighborhoodLoaded}
              showScatterplotControls={!user}
            />
          </div>
        </div>
        <div className={'flex flex-grow-0 flex-shrink-0 mr-2 mb-2'} style={{flexBasis: sidebarWidth, height: articleSummaryHeight}}>
          <ArticleSummary
            selectedArticle={selectedArticle}
            selectedArticleAuthors={selectedArticleAuthors}
            selectedArticlePmid={selectedArticle.pmid}
            neighborhoodArticlePmid={neighborhoodArticlePmid}
            setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
            scatterplotVisibility={scatterplotVisibility}
            authorListVisibility={authorListVisibility}
            setScatterplotVisibility={setScatterplotVisibility}
            setAuthorListVisibility={setAuthorListVisibility}
            setNeighborhoodLoaded={setNeighborhoodLoaded}
          />
        </div>
      </div>
      <ContactSupport />
    </>
  )
}

export default ArticleNeighborhood
