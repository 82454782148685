import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'
import useViewer from '../hooks/useViewer'
import magic from '../lib/magic'
import EmailForm from './EmailForm'
import gradient from '../lib/gradient'

const Login = () => {
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false)
  const { viewer: user } = useViewer()

  useEffect(() => {
    user && user.email && navigate('/')
  }, [user, navigate])

  async function handleLoginWithEmail(email) {
    await magic.user.logout()
    try {
      setDisabled(true)
      await magic.auth.loginWithMagicLink({
        email,
        redirectURI: new URL('/callback', window.location.origin).href,
      })
    } catch (e) {
      setDisabled(false)
    }
  }

  return (
    <div className={'overflow-auto'} style={{height: window.innerHeight}}>
      <Helmet><title>Gurney - Log in</title></Helmet>
      <div className={'h-full w-full'} style={{background: gradient}}>
        <div className={'bg-stone-50'}>
          <div className={'px-8 py-4'}>
            <Logo />
          </div>
          <div className={'pl-2 flex flex-col sm:items-evenly sm:items-center'}>
            <div className={'p-8 sm:p-0'}>
              <div><span className={'font-sans text-3xl text-stone-700'}>Log in</span></div>
              <div className={'font-sans text-lg login py-12'}>
                <EmailForm disabled={disabled} onEmailSubmit={handleLoginWithEmail} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
