import { Helmet } from 'react-helmet-async'
import Logo from './Logo'

function Faq () {
  return(
    <div className={'overflow-auto'} style={{height: window.innerHeight}}>
      <Helmet><title>Gurney - FAQ</title></Helmet>
      <div className={'h-full w-full bg-stone-50'}>
        <div className={'h-fit bg-stone-50'}>
          <div className={'px-8 py-4'}>
            <Logo />
          </div>
          <div className={'flex flex-col items-evenly items-center p-4'}>
            <div><span className={'font-serif text-stone-700 text-4xl'}>FAQ</span></div>
            <div className={'flex flex-row'}>
              <div className={'flex flex-col sm:w-1/4'}></div>
              <div className={'flex flex-col sm:w-1/2 pt-8 pb-2 pl-4 text-stone-700'}>
                <ol className={'text-lg'}>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>What is Gurney?</span>
                    </div>
                    <div className={'py-2'}>
                      <span>
                        Gurney is a web app for finding PubMed literature worth reading. It's intended to be used on large screens.
                      </span>
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>What is PubMed?</span>
                    </div>
                    <div className={'py-2'}>
                      <span>
                        <a href='https://pubmed.ncbi.nlm.nih.gov/' className='underline hover:text-stone-500'>PubMed</a> is an open archive of biomedical literature. It is maintained by the United States National Library of Medicine.
                      </span>
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>How does Gurney differ from the PubMed website and Google Scholar?</span>
                    </div>
                    <div className={'py-2'}>
                      <span>
                        Unlike PubMed, Google Scholar and other search engines, Gurney's interface design is based on an interactive data visualization. Our goal is to make it easier for users to contextualize a given scientific paper within the discourse of its field.
                      </span>
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>How does Gurney access PubMed data? Is it up-to-date?</span>
                    </div>
                    <div className={'py-2'}>
                      <p>Gurney's data is up to date with PubMed's through June 2023. Gurney previously ingested daily updates from PubMed.</p>
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>Who builds and maintains Gurney?</span>
                    </div>
                    <div className={'py-2'}>
                      Gurney is designed, built and maintained by Laila Winner, a software engineer, and Saurabh Asthana, a computational biologist.
                    </div>
                    <div className={'py-2'}>
                      Laila previously worked on software tools for detecting spam and fake accounts at GitHub. Saurabh received his PhD from Harvard Medical School and presently works at the University of California, San Francisco.
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>Is Gurney under active development?</span>
                    </div>
                    <div className={'py-2'}>
                      <span>
                        No, but if you encounter bugs please feel free to let us know so we can fix them. Gurney was under active development January 2022 - June 2023 and is now in maintenance mode.
                      </span>
                    </div>
                  </li>
                  <li className={'py-2'}>
                    <div>
                      <span className={'font-bold'}>Why "Gurney"?</span>
                    </div>
                    <div className={'py-2'}>
                      It's fun to say, yet also imbued with a sense of medical urgency.
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-row w-full font-sans bg-stone-50 text-stone-700 text-sm place-content-center py-4'}>
          <span className={'mr-4'}>© Gurney 2023</span>
          <a href='https://gurney.canny.io/' className={'font-sans mr-4 underline'}>Roadmap</a>
          <a href='/faq' className={'font-sans text-sm underline'}>FAQ</a>
        </div>
      </div>
    </div>
  )
}

export default Faq
