import numeral from 'numeral'
import neighborTypeColors from './../lib/neighborTypeColors'

const neighborTypeLabelText = {
  'ANCESTOR': 'ANCESTOR',
  'SIBLING': 'CO-CITATION',
  'DESCENDANT': 'DESCENDANT',
  'AUTHORED_BY_TOP_NEIGHBORHOOD_AUTHOR': 'AUTHORED BY TOP NEIGHBORHOOD AUTHOR'
}

const FocusedArticleTooltip = ({ props }) => {
  const { article, x, y } = props

  if (!article) {
    return (<></>)
  }

  const {
    citationsCount,
    citedByArticlePmids,
    journalTitle,
    neighborType,
    pmid,
    title,
    formattedPublicationDate,
  } = article
  const formattedCitationsCount = numeral(citationsCount).format('0a')
  const formattedNeighborhoodCitationsCount = numeral(citedByArticlePmids.length).format('0a')
  let neighborTypeLabel = ''

  if (neighborType) {
    const neighborTypeBgClass = neighborTypeColors[neighborType]?.bgClass
    const textColor = neighborTypeColors[neighborType]?.textColor
    neighborTypeLabel = (
      <span className={`${neighborTypeBgClass} font-sans text-sm ${textColor} uppercase px-1 mr-1`} data-pmid={pmid}>{neighborTypeLabelText[neighborType]}</span>
    )
  }

  const similarlyTitled = article.titleSimilarity >= 0.4
  const similarlyTitledLabel = !similarlyTitled ? '' : (
    <span className={'bg-green-400 font-sans text-sm text-black uppercase px-1 mr-1'} data-pmid={pmid}>similarly titled</span>
  )

  if (article) {
    const offsetX = x + 10
    const top = `${y}px`
    const left = `${offsetX}px`

    return (
      <div className={`tooltip absolute bg-white mt-2 p-2 shadow-lg shadow-stone-700/75`} style={{ top, left }}>
        <div className={'flex flex-column space-y-2'}>
          <div className={'w-fit border-none flex-row bg-white'}>
            <div data-pmid={pmid} className={'w-full bg-white space-y-2 p-4 text-left'}>
              {neighborTypeLabel}
              {similarlyTitledLabel}
              <span className={'font-sans text-base my-1'} data-pmid={pmid}>
                <span className={'italic'}>{journalTitle}</span>
                <span>, {formattedPublicationDate}.</span>
              </span>
              <p className={'font-serif text-lg my-1'} data-pmid={pmid}>{title}</p>
              <p className={'font-sans text-base my-1'}>{formattedCitationsCount} known | {formattedNeighborhoodCitationsCount} neighborhood citations</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FocusedArticleTooltip
