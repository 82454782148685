import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Logo from './Logo'
import useViewer from '../hooks/useViewer'
import gradient from '../lib/gradient'
import magic from '../lib/magic'

const Callback = ({ loginEndpointUrl }) => {
  const navigate = useNavigate()
  const [didToken, setDidToken] = useState(null)
  const [loginError, setLoginError] = useState(null)
  const { setViewer: setUser } = useViewer()
  const [searchParams] = useSearchParams()
  const [magicCredential] = useState(searchParams.get('magic_credential'))
  const logout = async () => await magic.user.logout()
  const authenticateCredential = async () => {
    const userDidToken = await magic.auth.loginWithCredential(magicCredential)
    setDidToken(userDidToken)
  }

  const login = async () => {
    if (loginError) { return }

    if (didToken) {
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Authorization': `Bearer ${didToken}` },
      }
      const loginResponse = await window.fetch(
        loginEndpointUrl,
        requestOptions,
      )

      if (loginResponse.ok) {
        const currentUser = await magic.user.getMetadata()
        setUser(currentUser)
        return navigate('/previously-viewed')
      } else {
        setLoginError(new Error('Login failed'))
      }
    }
  }

  useEffect(() => {
    authenticateCredential()
  }, [magicCredential])

  useEffect(() => {
    login(didToken)
  }, [didToken])

  useEffect(() => {
    if (loginError) { logout() }
  }, [loginError])

  if (loginError) { return(<div><h1>Error: {loginError.message}</h1></div>) }

  return(
    <div className={'overflow-auto'} style={{height: window.innerHeight}}>
      <div className={'h-full w-full'} style={{background: gradient}}>
        <div className={'bg-stone-50'}>
          <div className={'flex flex-row w-full items-center'}>
            <div className={'flex-1 px-8 py-4'}>
              <Logo />
            </div>
          </div>
          <div className={'flex flex-row'}>
              <div className={'flex flex-col w-1/3'}></div>
              <div className={'flex flex-col w-1/3'}>
                <span className={'py-8 font-sans text-5xl text-stone-700'}>Signing in ...</span>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Callback
