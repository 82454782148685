import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import ContactSupport from './ContactSupport'
import Header from './Header'
import sciteExampleImage from '../assets/scite-example-image.png'
import zoteroExampleImage from '../assets/zotero-example-image.png'
import useViewer from '../hooks/useViewer'

const headerHeight = 80

const Integrations = () => {
  const { viewer: user } = useViewer()
  const [neighborhoodArticlePmid, setNeighborhoodArticlePmid] = useState('')
  const height = window.innerHeight - headerHeight

  return(
    <>
      <Helmet><title>Gurney - Integrations</title></Helmet>
      <Header
        neighborhoodArticlePmid={neighborhoodArticlePmid}
        setNeighborhoodArticlePmid={setNeighborhoodArticlePmid}
      />
      <div className={'overflow-auto mb-4'} style={{ height }}>
        <div className={'flex flex-row sm:px-44 px-8 pt-8'}><span className={'font-sans text-3xl text-stone-900'}>Integrations</span></div>
        <div className={'flex flex-row sm:px-44 px-8 pt-8'}><span className={'font-sans text-2xl text-stone-900'}>1. Zotero</span></div>
        <div className={'flex flex-col space-y-2 sm:px-44 px-8 py-4 font-sans text-lg text-stone-900'}>
          <span>Gurney is for finding literature worth reading; <a href={'https://www.zotero.org/'} className={'underline hover:text-stone-500'}>Zotero</a> is for keeping track of it.</span>
          <span>Zotero is a free, open-source software tool maintained by the <a className={'underline hover:text-stone-500'} href='https://digitalscholar.org/'>Corporation for Digital Scholarship</a>.</span>
        </div>
        <div className={'flex flex-col sm:px-44 px-8 py-4'}>
          <span className={'font-sans text-xl text-stone-900'}>Installation and usage</span>
          <ol className={'sm:p-4 space-y-4 list-decimal text-lg'}>
            <li>
              <span>
                Download and install <a className={'underline hover:text-stone-500'} href='https://www.zotero.org/download'>Zotero</a> for Mac, Windows or Linux
              </span>
            </li>
            <li>
              <span>
                Download and install <a className={'underline hover:text-stone-500'} href='https://www.zotero.org/download'>Zotero Connector</a> browser extension for Chrome, Firefox, Edge or Safari
              </span>
            </li>
            <li>
              <span>Select an article in Gurney and right-click to save it to Zotero using Zotero Connector</span>
              <img className={'shadow-md shadow-stone-700/75 mt-4'} src={zoteroExampleImage} alt={'Zotero integration example'} />
            </li>

          </ol>
        </div>
        <div className={'flex flex-row sm:px-44 px-8 pt-8'}><span className={'font-sans text-2xl text-stone-900'}>2. scite</span></div>
        <div className={'flex flex-col space-y-2 sm:px-44 px-8 py-4 font-sans text-lg text-stone-900'}>
          <span><a href={'https://www.scite.ai/'} className={'underline hover:text-stone-500'}>scite</a> is a research platform that contextualizes research papers by surfacing supporting and contrasting citations.</span>
        </div>
        <div className={'flex flex-col sm:px-44 px-8 py-4'}>
          <span className={'font-sans text-xl text-stone-900'}>Installation and usage</span>
          <ol className={'sm:p-4 space-y-4 list-decimal text-lg'}>
            <li>
              <span>
                Download and install <a className={'underline hover:text-stone-500'} href='https://scite.ai/extension-install'>scite browser extension</a>
              </span>
            </li>
            <li>
              <span>Select the title or abstract text of an article in Gurney and right-click to search scite for supporting and contrasting citations</span>
              <img className={'shadow-md shadow-stone-700/75 mt-4'} src={sciteExampleImage} alt={'scite integration example'} />
            </li>
          </ol>
        </div>
        <ContactSupport />
      </div>
    </>
  )
}

export default Integrations
