const ArticleNeighborhoodFilters = ({
  keyword,
  maxKnownCitationsCount,
  minKnownCitationsCount,
  maxNeighborhoodCitationsCount,
  minNeighborhoodCitationsCount,
  setMinKnownCitationsCount,
  setMinNeighborhoodCitationsCount,
  setKeyword,
}) => {
  return (
    <div className={`flex flex-row align-middle font-sans text-sm text-stone-700 overflow-hidden max-h-20 h-20 pl-8 py-2 border-b border-solid border-stone-200 bg-stone-200`}>
      <div className={'flex flex-col'}>
        <div className={'flex flex-row'}>
          <div className={'flex flex-col align-middle pr-12'}>
            <label className={'mb-2'} for={'known-citations-count-input'}>Known citations</label>
            <input className={'h-1 mb-2 bg-stone-500 rounded-lg cursor-pointer range-sm accent-stone-500'} style={{ width: '300px' }} list={'known-citations-count-tick-values'} type={'range'} onChange={({ target }) => setMinKnownCitationsCount(target.value)} value={minKnownCitationsCount} min={'0'} max={maxKnownCitationsCount} id={'known-citations-count-input'}></input>
            <datalist className={'flex flex-row justify-between'} id={'known-citations-count-tick-values'}>
              <option className={'p-0'} value={'0'} label={'0'} />
              <option className={'p-0'} value={maxKnownCitationsCount} label={maxKnownCitationsCount} />
            </datalist>
          </div>

          <div className={'flex flex-col pr-12'}>
            <label className={'mb-2'} for={'neighborhood-citations-count-input'}>Neighborhood citations</label>
            <input className={'h-1 mb-2 bg-stone-500 rounded-lg cursor-pointer range-sm accent-stone-500'} style={{ width: '300px' }} list={'neighborhood-citations-count-tick-values'} type={'range'} onChange={({ target }) => setMinNeighborhoodCitationsCount(target.value)} value={minNeighborhoodCitationsCount} min={'0'} max={maxNeighborhoodCitationsCount} id={'neighborhood-citations-count-input'}></input>
            <datalist className={'flex flex-row justify-between'} id={'neighborhood-citations-count-tick-values'}>
              <option className={'p-0'} value={'0'} label={'0'} />
              <option className={'p-0'} value={maxNeighborhoodCitationsCount} label={maxNeighborhoodCitationsCount} />
            </datalist>
          </div>

          <div className={'flex flex-col pt-2 pr-12'}>
            <input
              className={'outline-none [webkit-appearance:none] w-72 p-2 border-r-2 border-y-2 border-solid border-stone-200'}
              onChange={({ target }) => setKeyword(target.value.toLowerCase())}
              placeholder={'Title or journal title keyword'}
              value={keyword}
              id={'keyword'}
            />
          </div>
        </div>
      </div>
      <div className={'flex flex-col w-full items-end pt-2 pr-8'}>
        <div className={'flex flex-row font-sans'}>
          <button className={'p-2 bg-stone-50'} onClick={() => {
            setKeyword('')
            setMinNeighborhoodCitationsCount(0)
            return setMinKnownCitationsCount(0)
          }}>
            Clear filters
          </button>
        </div>
      </div>
    </div>
  )
}


export default ArticleNeighborhoodFilters
