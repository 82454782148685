import { gql, useApolloClient } from '@apollo/client'
import constate from 'constate'
import { useEffect, useState } from 'react'
const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      email
    }
  }
`

function useViewerState() {
  const [viewer, setViewer] = useState(undefined)
  return { viewer, setViewer }
}

const [ViewerContextProvider, useViewerContext] = constate(useViewerState)

function LoadViewer({ children }) {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const { setViewer } = useViewerContext()

  useEffect(
    () => {
      let isSubscribed = true

      client
        .query({ query: CURRENT_USER })
        .then(({ data, errors }) => {
          if (isSubscribed) {
            if (errors) {
              setViewer(undefined)
            } else if (data?.currentUser) {
              const email = data.currentUser.email
              setViewer({ email })
            }
            setLoading(false)
          }
        })
        .catch((error) => {
          setViewer(undefined)
          setLoading(false)
        })

      return () => {
        isSubscribed = false
      }
    }, [client, setLoading, setViewer])

  if (loading) { return null }

  return children
}

function PresetViewer({ children, viewer }) {
  const { setViewer, viewer: currentViewer } = useViewerContext()

  useEffect(() => {
    if (currentViewer === undefined) {
      setViewer(viewer)
      return null
    }
  })

  return children
}

export function ViewerProvider({ children, viewer }) {
  if (viewer !== undefined) {
    return (
      <ViewerContextProvider>
        <PresetViewer viewer={viewer}>
          {children}
        </PresetViewer>
      </ViewerContextProvider>
    )
  }

  return (
    <ViewerContextProvider>
      <LoadViewer>
        {children}
      </LoadViewer>
    </ViewerContextProvider>
  )
}

export default function useViewer() {
  const { viewer, setViewer } = useViewerContext()
  return { viewer, setViewer }
}
