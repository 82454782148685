import { Navigate } from 'react-router-dom'
import useViewer from '../hooks/useViewer'

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}) => {
  const { viewer } = useViewer()
  if (!viewer) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default ProtectedRoute
