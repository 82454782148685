import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineLogout, AiOutlineSearch } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import useViewer from '../hooks/useViewer'
import magic from '../lib/magic'
import Logo from './Logo'

const DOI_REGEXP = new RegExp(/^10\.\d{4,9}/)

const ARTICLE_BY_DOI = gql`
  query ArticleByDoi($doi: String!) {
    article(doi: $doi) {
      pmid
    }
  }
`

function Header ({
  setNeighborhoodArticlePmid,
  neighborhoodArticlePmid,
  showLoadingIndicator = false,
}) {
  const initialAnimationClass = showLoadingIndicator ? 'animate-pulse' : ''
  const { viewer: user, setViewer: setUser } = useViewer()
  const [animationClass, setAnimationClass] = useState(initialAnimationClass)
  const [searchQuery, setSearchQuery] = useState(neighborhoodArticlePmid)
  const [searchQueryDoi, setSearchQueryDoi] = useState(null)
  const [searchErrorVisibility, setSearchErrorVisibility] = useState('invisible')
  const location = useLocation()
  const { called, loading: articleLoading, error, data } = useQuery(
    ARTICLE_BY_DOI,
    { variables: { doi: searchQueryDoi }, skip: !searchQueryDoi }
  )
  const navItemClass = (currentPath) => location.pathname === currentPath ? 'underline' : 'hover:underline'
  const navigate = useNavigate()
  const logout = async () => {
    const requestOptions = {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
    }
    const logoutEndpointUrl = process.env.NODE_ENV === 'development' ?
      'http://localhost:8080/api/logout' :
      'https://api.gurney.science/api/logout'
    const logoutResponse = await window.fetch(
      logoutEndpointUrl,
      requestOptions,
    )

    if (logoutResponse.ok) {
      await magic.user.logout()
      setUser(null)
      navigate('/')
    } else {
      throw new Error('Logout failed')
    }
  }
  const loggedIn = !!user?.email

  const navigateToArticleNeighborhood = () => {
    const pmidPath = `/pmid/${neighborhoodArticlePmid}`
    navigate(pmidPath)
  }

  const updateSearchQuery = (e) => {
    return setSearchQuery(e.target.value.trim())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSearchErrorVisibility('invisible')

    if (DOI_REGEXP.test(searchQuery)) {
      setSearchQueryDoi(searchQuery)
    } else {
      const pmid = parseInt(searchQuery)

      if (pmid === 0) {
        setSearchErrorVisibility('visible')
      } else {
        setNeighborhoodArticlePmid(parseInt(searchQuery))
      }
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') { handleSubmit(e) }
  }

  React.useEffect(() => {
    setSearchErrorVisibility('invisible')
    setSearchQuery(neighborhoodArticlePmid)

    if (neighborhoodArticlePmid.toString().length > 0) {
      navigateToArticleNeighborhood()
    }
  }, [neighborhoodArticlePmid])

  React.useEffect(() => {
    const newAnimationClass = showLoadingIndicator ? 'animate-pulse' : ''
    setAnimationClass(newAnimationClass)
  }, [showLoadingIndicator])

  React.useEffect(() => {
    if (called && articleLoading) { return }

    if (error || (searchQueryDoi && !data?.article)) {
      setSearchErrorVisibility('visible')
      return
    }

    if (data && data.article.pmid) {
      setNeighborhoodArticlePmid(data.article.pmid)
    }
  }, [searchQueryDoi, data, error, articleLoading])

  const onMouseOver = ({ target }) => {
    const cursorStyle = 'pointer'
    return target.style.cursor = cursorStyle
  }

  const requestInvitationUrl = `
    https://docs.google.com/forms/d/e/1FAIpQLSd-Gv7AkPQ65F1Tc_fFIomGw_llo1Ztyfnw6KK1xqMwalZrPg/viewform
  `

  return(
    <>
      <div className={'flex flex-row flex-grow-0 flex-shrink-0 items-center px-8 mb-2 space-x-4 sm:space-x-12 bg-white'}>
        <div className={'pr-2'}>
          <Logo />
        </div>

        <div className={'flex-1 flex-column'}>
          <div className={'flex flex-row align-middle font-sans text-xl pt-4'}>
            <IconContext.Provider value={{ style: { padding: '8px', borderLeft: '2px solid #e7e5e4', borderTop: '2px solid #e7e5e4', borderBottom: '2px solid #e7e5e4', backgroundColor: 'white',  display: 'inline-flex', color: '#44403c' } }}>
              <AiOutlineSearch
                size={44}
                title={'Search by DOI or PMID'}
              />
            </IconContext.Provider>

            <input
              className={'outline-none [webkit-appearance:none] w-28 h-[44px] sm:w-72 p-2 border-r-2 border-y-2 border-solid border-stone-200'}
              onKeyPress={onKeyPress}
              onChange={updateSearchQuery}
              placeholder={'Search by DOI or PMID'}
              value={searchQuery}
            />
          </div>
          <div className={'flex-1 flex-row content-evenly font-sans text-base'}>
            <span className={`${searchErrorVisibility} font-sans text-xs text-red-700`}>Article not found</span>
          </div>
        </div>

        {!loggedIn && (
          <div className={'hidden sm:flex flex-row space-x-12 bg-white font-sans text-base'}>
            <a href='/integrations' className={navItemClass('/integrations')}>Integrations</a>
            <a href='https://gurney.canny.io/feature-requests' className={'hover:underline'}>Share feedback</a>
            <a href='/login' className={navItemClass('/login')}>Log in</a>
            <a href={requestInvitationUrl} className={'font-bold text-emerald-700 hover:underline'}>✨ Create an account to access your browsing history ✨</a>
          </div>
        )}

        {loggedIn && (
          <div className={'hidden sm:flex flex-row space-x-12 bg-white font-sans text-base'}>
            <a href='/integrations' className={navItemClass('/integrations')}>Integrations</a>
            <a href='https://gurney.canny.io/feature-requests' className={'hover:underline'}>Share feedback</a>
            <a href='/previously-viewed' className={navItemClass('/previously-viewed')}>Previously viewed</a>

            <div>
              <div className={'flex flex-row space-x-2 pl-2'}>
                <span>{user.email}</span>

                <button onClick={logout} onMouseOver={onMouseOver}>
                  <IconContext.Provider value={{ style: { color: '#44403c' } }}>
                    <AiOutlineLogout title={'Log out'} size={20} />
                  </IconContext.Provider>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`w-full opacity-90 shadow-md shadow-stone-500/50] ${animationClass}`} style={{height: '8px', background: 'linear-gradient(45deg, rgba(236,72,153,1) 0%, rgba(147,51,234,1) 25%, rgba(59,130,246,1) 50%, rgba(74,222,128,1) 70%, rgba(250,204,21,1) 90%)'}}></div>
    </>
  )
}

export default Header
