import { IconContext } from 'react-icons'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

function ContactSupport() {
  const onMouseOver = ({ target }) => {
    return target.style.cursor = 'pointer'
  }

  return(
    <div className={'absolute bottom-4 right-8'}>
      <a href='mailto:support@gurney.science' onMouseOver={onMouseOver}>
        <IconContext.Provider value={{ style: { filter: 'drop-shadow(2px 2px 1px #d1fae5)' , color: '#047857' } }}>
          <AiOutlineQuestionCircle title={'Contact Support'} size={35} />
        </IconContext.Provider>
      </a>
    </div>
  )
}

export default ContactSupport
