import { gql, useQuery } from '@apollo/client'
import { groupBy, uniq, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import Scatterplot from './Scatterplot'
import ScatterplotControls from './ScatterplotControls'

const ScatterplotContainer = ({
  dimensions,
  keyword,
  minKnownCitationsCount,
  minNeighborhoodCitationsCount,
  neighborhoodArticlePmid,
  neighborhoodLoaded,
  selectedArticle,
  setMaxKnownCitationsCount,
  setMaxNeighborhoodCitationsCount,
  setSelectedArticle,
  showScatterplotControls,
  setNeighborhoodLoaded,
}) => {
  const ARTICLE_NEIGHBORHOOD = gql`
    query ArticleNeighborhood($pmid: Int!) {
      articleNeighborhood(pmid: $pmid) {
        neighborhoodEdges {
          pmid
          refArticleId
        }
        neighborhoodJournals {
          articlesCount
          index
          journal
          maxY
          minY
        }
        neighborhoodJournalLabels {
          name
          minY
          maxY
        }
        article {
          pmid
          title
          journalTitle
          citationsCount
          publishedOn
          x
          y
          authors {
            foreName
            lastName
            affiliation
          }
        }
        articleNeighbors {
          pmid
          title
          journalTitle
          citationsCount
          publishedOn
          x
          y
          doi
          neighborType
          titleSimilarity
        }
      }
    }
  `
  const variables =  { pmid: neighborhoodArticlePmid }
  const [articles, setArticles] = useState([])
  const [scatterplotControlsIsExpanded, setScatterplotControlsIsExpanded] = useState(showScatterplotControls)
  const [neighborhoodJournals, setNeighborhoodJournals] = useState([])
  const [neighborhoodJournalLabels, setNeighborhoodJournalLabels] = useState([])
  const { called, loading, error, data } = useQuery(ARTICLE_NEIGHBORHOOD, { variables })

  React.useEffect(() => {
    if (loading || error) { return }
    const {
      neighborhoodEdges,
      article: articleData,
      articleNeighbors: articleNeighborsData,
      neighborhoodJournals,
      neighborhoodJournalLabels,
    } = data.articleNeighborhood
    const citations = groupBy(neighborhoodEdges, 'refArticleId')
    const references = groupBy(neighborhoodEdges, 'pmid')
    const articlesData = uniqBy(articleNeighborsData.concat([articleData]), 'pmid').filter((article) => article)

    const articles = articlesData.map((article) => {
      const referencedArticlePmids = uniq(references[article.pmid]?.map(({ refArticleId }) => refArticleId) || [])
      const citedByArticlePmids = uniq(citations[article.pmid]?.map(({ pmid }) => pmid) || [])
      const citationCount = citedByArticlePmids.length
      const formattedPublicationDate = DateTime.fromISO(article.publishedOn).toFormat('yyyy LLL')

        return {
          ...article,
          citedByArticlePmids,
          referencedArticlePmids,
          citationCount,
          formattedPublicationDate,
        }
    })
    const m = articles.reduce((acc, { citationsCount }) => {
      if (citationsCount > acc) {
        acc = citationsCount
      }

      return acc
    }, 0)

    const nm = articles.reduce((acc, { citedByArticlePmids }) => {
      if (citedByArticlePmids.length > acc) {
        acc = citedByArticlePmids.length
      }

      return acc
    }, 0)

    setMaxKnownCitationsCount(m)
    setMaxNeighborhoodCitationsCount(nm)
    setArticles(articles)
    setNeighborhoodJournals(neighborhoodJournals)
    setNeighborhoodJournalLabels(neighborhoodJournalLabels)
  }, [neighborhoodArticlePmid, data, error, loading])

  if (error) {
    return(
      <div className={'flex flex-1'}>
        <div></div>
        <div className={'flex-1'}>
          <p className={'m-12 font-serif text-4xl text-center'}>This neighborhood took too long to load</p>
        </div>
      </div>
    )
  }

  if (loading || articles.length === 0) {
    return(
      <div className={'flex flex-1'}>
        <div></div>
        <div className={'flex-1'}>
          <p className={'m-12 font-serif text-4xl text-center'}>Building article neighborhood</p>
          <p className={'m-12 font-serif text-3xl text-center'}>(This can take up to a minute)</p>
        </div>
      </div>
    )
  }

  const neighbors = data?.articleNeighborhood?.articleNeighbors
  if (called && !loading && neighbors && neighbors.length === 0) {
    return(
      <div className={'flex flex-1'}>
        <div></div>
        <div className={'flex-1'}>
          <p className={'m-12 font-serif text-4xl text-center'}>This neighborhood is empty! No references or citations found ☹️</p>
        </div>
      </div>
    )
  }

  if (error) { return(<h1>Error</h1>) }

  return (
    <div className={'static'}>
      <ScatterplotControls
        isExpanded={scatterplotControlsIsExpanded}
        setIsExpanded={setScatterplotControlsIsExpanded}
      />
      <Scatterplot
        dimensions={dimensions}
        articles={articles}
        keyword={keyword}
        minKnownCitationsCount={minKnownCitationsCount}
        minNeighborhoodCitationsCount={minNeighborhoodCitationsCount}
        neighborhoodJournals={neighborhoodJournals}
        neighborhoodJournalLabels={neighborhoodJournalLabels}
        neighborhoodArticlePmid={neighborhoodArticlePmid}
        neighborhoodLoaded={neighborhoodLoaded}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setNeighborhoodLoaded={setNeighborhoodLoaded}
      />
    </div>
  )
}

export default ScatterplotContainer
