import { IconContext } from 'react-icons'
import { MdOutlineScatterPlot } from 'react-icons/md'
import { SiPubmed } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'

const ArticleActions = ({
  selectedArticlePmid,
  setNeighborhoodArticlePmid,
  setNeighborhoodLoaded,
}) => {
  const navigate = useNavigate()
  const openInPubMed = () => {
    const pubMedUrl = `https://pubmed.ncbi.nlm.nih.gov/${selectedArticlePmid}`
    const newWindow = window.open(pubMedUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) { newWindow.opener = null }
  }
  const viewNeighborhood = (event) => {
    event.preventDefault()

    const pmidPath = `/pmid/${selectedArticlePmid}`
    setNeighborhoodArticlePmid(selectedArticlePmid)
    setNeighborhoodLoaded(false)
    navigate(pmidPath)
  }
  const stone700HexCode = '#44403c'
  const iconContainerClasses = 'flex flex-row space-x-4 pl-2 py-2'
  const viewNeighborhoodButton = (
    <button>
      <IconContext.Provider value={{ style: { color: stone700HexCode } }}>
        <div className={iconContainerClasses}>
          <MdOutlineScatterPlot
            size={25}
            onClick={viewNeighborhood}
            title={'View neighborhood'}
          />
        </div>
      </IconContext.Provider>
    </button>
  )

  return(
    <>
      <div className={'flex flex-1 flex-row content-evenly py-2 border-t-0 border-solid border-stone-200'}>
        <div className={'ml-4 my-4 mr-2'}>
          <button onClick={openInPubMed}>
            <IconContext.Provider value={{ style: { color: stone700HexCode } }}>
              <div className={iconContainerClasses}>
                <SiPubmed
                  size={25}
                  title={'Open in PubMed'}
                />
              </div>
            </IconContext.Provider>
          </button>
        </div>
        <div className={'my-4 mr-2'}>
          {viewNeighborhoodButton}
        </div>
      </div>
    </>
  )
}
export default ArticleActions
