import { useState } from 'react'

const EmailForm = ({ onEmailSubmit, disabled }) => {
  const [email, setEmail] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    onEmailSubmit(email)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={'font-sans text-xl'}>
          <input
            className={'p-2 border-2 border-solid border-stone-200 focus:outline-none'}
            onChange={(event) => setEmail(event.target.value)}
            placeholder={'Enter your email'}
          />
          <button
            className={'bg-stone-200 p-2 border-2 border-solid border-stone-200 text-stone-700'}
            disabled={disabled}
            onClick={handleSubmit}
          >
            Send Magic Link
          </button>
        </div>
      </form>
    </>
  )
}

export default EmailForm
