import { orderBy, sortBy, uniq } from 'lodash'

const pluralize = ({ string, count }) =>  count === 1 ? string : `${string}s`
const normalize = ({ string }) => string.toLowerCase().replace(/(,|\.)/g, '')

const ArticleAuthors = ({ selectedArticleAuthors: authors, height }) => {
  const filteredUniqueAffiliations = (affiliations) => {
    return sortBy(uniq(affiliations), 'length').filter((a) => a !== '').reduce((acc, affiliation) => {
      if (acc.length === 0) {
        acc.push(affiliation)
        return acc
      } else {
        const matches = acc.filter((a) => normalize({ string: affiliation })
          .includes(normalize({ string: a })))

        if (matches.length === 0) {
          acc.push(affiliation)
        }
        return acc
      }
    }, [])
  }

  const topAffiliations = filteredUniqueAffiliations(authors.map(({ affiliation }) => affiliation)).sort()

  return (
    <div>
      <div className={'flex flex-col pb-2 text-stone-900 overflow-auto'} style={{height}}>
        <div className={'flex flex-row items-top py-4 pl-8 border-b-2 border-solid border-stone-200'}>
          <div className={'flex flex-col w-1/6 pt-2'}>
            <span className={'font-serif text-2xl pr-8'}>Author affiliations</span>
          </div>
          <div className={'flex flex-col w-5/6 mr-2'}>
            <ol className={'list-disc font-sans ml-8'}>
              {topAffiliations.map((affiliation, i) => {
                const borderClasses = i === topAffiliations.length - 1 ? '' : 'border-b-2 border-dotted border-stone-200'
                return (<li key={i} className={`py-2 text-lg ${borderClasses}`}>
                  <div>{affiliation}</div>
                </li>)
              })}
            </ol>
          </div>
        </div>
        <div className={'flex flex-row items-top pl-8 border-b-2 border-solid border-stone-200'}>
          <div className={'flex flex-col w-1/6'}>
            <span className={'font-serif text-2xl pt-4 pr-8'}>Authors</span>
          </div>
          <div className={'flex flex-col w-5/6 mr-4'}>
            <ol className={'list-decimal font-sans ml-8'}>
              {authors.map((author, i) => {
                const borderClasses = i === 0 && authors.length > 1 ? 'border-b-2 border-dotted border-stone-200' : ''
                const authorData = authors?.find(({ authorId }) => authorId === author.authorId)
                const firstAuthorCreditsOrderedByCounts = orderBy((authorData?.firstAuthorCredits || []), 'count', 'desc')
                const authorArticleJournals = firstAuthorCreditsOrderedByCounts?.map(({ journalTitle }) => journalTitle) || []
                const top10AuthorArticleJournals = authorArticleJournals.slice(0, 10)
                let otherJournalsSpan = ''
                const publicationSummary = authorArticleJournals.length === 0 ?
                  '' :
                  (<span className={'text-lg'}>First author of {authorArticleJournals.length} {pluralize({ string: 'paper', count: authorArticleJournals.length})} published in <span className={'italic'}>{top10AuthorArticleJournals.join(', ')}</span></span>)
                if (authorArticleJournals.length > 10) {
                  otherJournalsSpan = (<span className={'text-lg'}> and {authorArticleJournals.length - 10} other {pluralize({ string: 'journal', count: authorArticleJournals.length - 10})}</span>)
                }
                return (<li key={i} className={`py-4 text-lg ${borderClasses}`}>
                  <div className={'text-xl'}><span className={'font-bold'}>{author.lastName}, {author.foreName}.</span> {author.affiliation}</div>
                  <div className={'pt-2'}>{publicationSummary}{otherJournalsSpan}</div>
                </li>)
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleAuthors
